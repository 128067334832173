export const routes = [
    {
        path: '/outflows',
        name: 'outflows.browse',
        component: () => import(/* webpackChunkName: "OutflowsBrowse" */ '@/views/app/OutflowsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/outflows/create',
        name: 'outflows.create',
        component: () => import(/* webpackChunkName: "OutflowsActions" */ '@/views/app/OutflowsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/outflows/:id/edit',
        name: 'outflows.edit',
        component: () => import(/* webpackChunkName: "OutflowsActions" */ '@/views/app/OutflowsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/outflows/:id/delete',
        name: 'outflows.delete',
        component: () => import(/* webpackChunkName: "OutflowsActions" */ '@/views/app/OutflowsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]